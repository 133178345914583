import Heading from "../../../common/components/Heading";
import Container from "../../../common/components/UI/Container";
import { TEAM_DATA } from "../../../common/data/WebApp";
import { Link } from "gatsby";
import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import { androidDone } from "react-icons-kit/ionicons/androidDone";
import { FaLinkedin } from "react-icons/fa";

import ProductArea, {
  CardBody,
  CardFooter,
  CardTop,
  Col,
  PriceCard,
  PricingAmount,
  Row,
  TopHeading,
} from "./product.style";

const Team = () => {
  return (
    <ProductArea id="pricing_section">
      <Container className="Container">
        <TopHeading>
          <Heading as="h2" content="Management Team" />
        </TopHeading>

        <Row>
          {TEAM_DATA?.map(
            (
              { product, title, price, tagline, planLabel, options, button },
              index
            ) => (
              <Col key={`pricing-card-key-${index}`}>
                <PriceCard className={product === 1 ? "recommended" : " "}>
                  <CardTop>
                    <Heading as="h2" content={title} />
                    <PricingAmount>
                      <Heading as="h5" content={price} />
                      {/* <Text as="p" content={tagline} /> */}
                    </PricingAmount>
                  </CardTop>
                  <CardBody>
                    {/* <Text
                      as="span"
                      className="pricingLabel"
                      content={planLabel}
                    /> */}
                    <ul className="priceList">
                      {options?.map(({ text }, index) => (
                        <li key={`priceList-key-${index}`}>{text}</li>
                      ))}
                    </ul>
                  </CardBody>
                  <CardFooter>
                    <Link
                      target="_blank"
                      className="priceBtn"
                      to={button?.link}
                    >
                      <span>
                        <FaLinkedin className="buttonIcon" />
                      </span>
                    </Link>
                  </CardFooter>
                </PriceCard>
              </Col>
            )
          )}
        </Row>
      </Container>
    </ProductArea>
  );
};

export default Team;
