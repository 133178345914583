import Banner from "../containers/WebApp/About/Banner";
import Section from "../containers/WebApp/About/Section";
import TeamBoard from "../containers/WebApp/TeamBoard";
import Team from "../containers/WebApp/Team";
import SEO from "../components/SEO";
import React from "react";

const About = () => {
  return (
    <>
      <SEO title="About" />
      <Banner />
      <Section />
      <Team />
    </>
  );
};

export default About;
